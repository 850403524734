export default {
  dark: {
    background: '#111111',
    surface: '#1E1E23',
    highlight: '#3D434A',
    anchor: '#FFA011',
    primary: '#FF4D00',
    secondary: '#829099',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
  },
};
