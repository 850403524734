<template>
  <div>
    <div v-if="mini">
      <img
        class="reppr-logo"
        src="@/assets/images/reppr_logo_dense.png"
        alt="reppr logo"
      />
    </div>
    <div v-else>
      <span style="color: #fff">rep</span>
      <span class="primary-text">pr</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mini: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.primary-text {
  color: var(--v-primary-base);
}

.reppr-logo {
  width: 100%;
  max-width: 40px;
}
</style>