<template>
  <v-app>
    <component :is="currentLayout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
import DefaultLayout from "./layouts/DefaultLayout";
import AuthLayout from "./layouts/AuthLayout";
export default {
  name: "App",
  components: {
    DefaultLayout,
    AuthLayout,
  },
  computed: {
    currentLayout() {
      const layout = this.$route.meta.layout || "Default";
      return layout + "Layout";
    },
  },
};
</script>

<style lang="scss">
html {
  overflow-y: auto;
}
</style>
