import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/movements',
    name: 'Movements',
    component: () =>
      import(/* webpackChunkName: "movements" */ '../views/Movements.vue'),
  },
  {
    path: '/routines',
    name: 'Routines',
    component: () =>
      import(/* webpackChunkName: "routines" */ '../views/Routines.vue'),
  },
  {
    path: '/routines/:id',
    name: 'Routine',
    component: () =>
      import(/* webpackChunkName: "routines" */ '../views/Routine.vue'),
  },
  {
    path: '/sessions',
    name: 'Sessions',
    component: () =>
      import(/* webpackChunkName: "Sessions" */ '../views/Sessions.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      noAuth: true,
      layout: 'Auth',
    },
    component: () =>
      import(/* webpackChunkName: "routines" */ '../views/Login.vue'),
    beforeEnter(_, __, next) {
      if (localStorage.getItem('jwt') === null) {
        return next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/*',
    name: 'not-found',
    component: () =>
      import(/* webpackChunkName: "routines" */ '../views/FourOhFour.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _, next) => {
  if (to.meta.noAuth) {
    return next();
  }
  if (localStorage.getItem('jwt') === null) {
    next({
      path: '/login',
      query: { next: to.fullPath },
    });
  } else {
    next();
  }
});

export default router;
