import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import theme from '../theme/theme';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    options: { customProperties: true },
    themes: {
      dark: theme.dark,
    },
  },
});
