<template>
  <div class="d-flex flex-grow-1">
    <v-navigation-drawer clipped app v-model="drawer" :mini-variant="mini">
      <template v-slot:prepend>
        <div class="pa-2">
          <div class="title text-h4">
            <reppr-text-logo :mini="mini"></reppr-text-logo>
          </div>
          <div v-if="!mini" class="overline grey--text">0.0.1</div>
        </div>
      </template>
      <v-list nav dense>
        <v-divider class="my-1"></v-divider>
        <v-list-item to="/">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content> Home </v-list-item-content>
        </v-list-item>

        <v-list-item to="/movements">
          <v-list-item-icon>
            <v-icon>mdi-human-handsup</v-icon>
          </v-list-item-icon>
          <v-list-item-content> Movements </v-list-item-content>
        </v-list-item>

        <v-list-item to="/routines">
          <v-list-item-icon>
            <v-icon>mdi-view-list</v-icon>
          </v-list-item-icon>
          <v-list-item-content> Routines </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item to="/sessions">Sessions</v-list-item> -->
      </v-list>
      <template #append>
        <div class="pa-2">
          <v-btn v-if="mini" fab small color="primary"
            ><v-icon>mdi-logout</v-icon></v-btn
          >
          <v-btn v-else color="primary" block @click="logout">Logout</v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar app clipped-left>
      <v-app-bar-nav-icon @click.stop="menuClick"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-main>
      <v-container>
        <slot></slot>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import RepprTextLogo from "../components/RepprTextLogo.vue";
export default {
  components: {
    RepprTextLogo,
  },
  data: () => ({
    drawer: true,
    mini: false,
  }),
  methods: {
    menuClick() {
      if (!this.drawer) {
        this.drawer = true;
        this.mini = false;
      } else {
        this.mini = !this.mini;
      }
    },
    logout() {
      localStorage.removeItem("jwt");
      this.$router.push("/login");
    },
  },
};
</script>